import AsyncStatus from "../../utils/async";
import forgotPassword from "../../api/mutations/forgotPassword";
import NotificationsMixin from "../ui/NotificationsMixin";
import mixins from "vue-typed-mixins";
import { rethrowPossibleApolloErrorAsUserMessage } from "../../api/apolloErrorToUserMessage";
export default mixins(NotificationsMixin).extend({
    data: function () {
        return {
            emailAddress: "",
            status: new AsyncStatus(),
        };
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.$validator.validate().then(function (valid) {
                if (valid) {
                    _this.status
                        .run(_this.$apollo
                        .mutate(forgotPassword(_this.emailAddress))
                        .catch(rethrowPossibleApolloErrorAsUserMessage))
                        .then(function () { return _this.$router.push("/forgot-password/sent"); });
                }
            });
        },
    },
});
